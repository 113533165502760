import getProductsByCollection from '@commerce/api/operations/get-products-by-collection'
import { Layout, SeoHead } from '@components/common'
import getGlobalStaticProps from '@lib/globalStaticProps'
import { SliceZone } from '@prismicio/react'
import { components } from '@slices/index'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { createClient, findCollectionHandles, getDocByUid } from 'prismicio'

export async function getStaticProps({
  locale,
  previewData,
}: GetStaticPropsContext) {
  const client = createClient({ previewData })
  const globalProps = await getGlobalStaticProps(client, locale)
  const translations = await serverSideTranslations(locale ?? 'en-au', [
    'common',
  ])

  let collectionProducts: any = []

  const doc = await getDocByUid('general', 'homepage', client, locale)
  const collectionHandles = findCollectionHandles(doc?.data?.slices)

  if (collectionHandles.length > 0) {
    for (let i = 0; i < collectionHandles.length; i++) {
      collectionProducts[i] = await getProductsByCollection(
        collectionHandles[i],
        locale,
        'no-cache'
      )
    }
  }

  return {
    props: {
      ...globalProps,
      ...translations,
      doc,
      collectionProducts,
    },
    //revalidate: 14400, // Refresh every 4 hours
  }
}

export default function Home({
  doc,
  collectionProducts,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { data } = doc || {}
  const { seoDescription, seoImage, seoTitle } = data || {}
  return (
    <>
      <SeoHead
        title={seoTitle || ''}
        description={seoDescription || ''}
        image={seoImage}
      />
      <SliceZone
        slices={doc?.data?.slices}
        components={components}
        context={{ collectionProducts }}
      />
    </>
  )
}

Home.Layout = Layout
